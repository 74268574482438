import React from 'react';
import { Link, graphql} from 'gatsby';
import Layout from '../components/layout';
import SEO from "../components/seo"
import ReactTypingEffect from "../vendors/react-typing-effect/src/lib";
import Slider from "react-slick";
import { Fade } from 'react-awesome-reveal';
/* IMPORT STATIC IMAGES */
import Joomla from "../assets/images/ico-joomla.png"
import Drupal from "../assets/images/ico-drupal.svg"
import WordPress from "../assets/images/ico-wp.svg"
import Jamstack from "../assets/images/jamstack_logo.png"

import Telstra from "../assets/images/logo-telstra.svg"
import AAOS from "../assets/images/logo-aas.svg"
import VicGov from "../assets/images/logo-vicgov.svg"
import Nylex from "../assets/images/logo-nylex.svg"
import Swinburne from "../assets/images/logo-swinburne.svg"
import Moose from "../assets/images/logo-moosetoys.svg"
import Transurban from "../assets/images/logo-transurban.svg"
import InfoXchange from "../assets/images/logo-infoxchange.svg"
import SRO from "../assets/images/logo-sro.svg"
import GWM from "../assets/images/logo-gwmwater.svg"
import UOM from "../assets/images/logo-unimelbourne.svg"
import Telkom from "../assets/images/logo-telkomtelstra.svg"
import Coliban from "../assets/images/logo-colibanwater.svg"
import VicPol from "../assets/images/logo-victoriapolice.svg"
import VTAC from "../assets/images/logo-vtac.svg"
import WorkSafe from "../assets/images/logo-worksafe.svg"
import EA from "../assets/images/logo-energyaustralia.svg"
import CFA from "../assets/images/logo-cfa.svg"

import UXImage from "../assets/images/ico-ux.svg"
import UIImage from "../assets/images/ico-ui.svg"
import WebImage from "../assets/images/ico-web.svg"
import BrandImage from "../assets/images/ico-brand.svg"



import BannerImage from "../assets/images/img1.svg"

import LetsWorkTogetherImage from '../assets/images/img-decor1.svg'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const settings = {
  dots: true,
  infinite: true,
  autoplaySpeed: 2500,
  lazyLoad: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 1,
  fade: true,
  adaptiveHeight: true,
  autoplay: false
};



const homeTemplate = ({data}) => {
return (

  <Layout>
  <SEO description="Webplace are a web design &amp; development agency based in Melbourne. We resolve business problems through customer understanding, award-winning digital web design, &amp; leading open source CMS technologies – incl. Wordpress, Joomla, &amp; Drupal. Contact our friendly team today for more information on what we can offer." title="Digital Agency | Web Design Melbourne" /> 
<main id="main">

      <strong className="sr-only">This is main content</strong>
      <div className="container py-10 py-lg-18">
      
        <div className="row align-items-center justify-content-between mb-8 mb-lg-25">
          <div className="col-md-7">
            <div className="h1 mb-4">
            We help organisations deliver&nbsp; 
            <ReactTypingEffect 
            typingDelay={500} 
            eraseDelay={1000} 
            speed={200}
            text={["Lightning Fast","Secure","Accessible","Usable"]}
          /><span className="wrap"></span> <br />Websites for their users
         </div>
           <div class="main_heading">We're a <h1>Melbourne Digital Agency</h1> with a focus on business transformation and user-centred outcomes</div>
           <div className="mt-2">
              <a href="/folio" className="btn btn-primary mr-2 mb-2">View our work</a>
              <a href="/lets-talk" className="btn btn-primary mt-xs-2  mb-2">Discuss your next project</a>
            </div>
          </div>
          <div className="col-md-4 d-print-none">
            <div className="img-holder text-right"><img src={BannerImage} width="305" height="304" alt="" /></div>
          </div>
        </div>
         
        <div className="bg-decor-section">
          <h2>Our recent work</h2>
          <div className="content-box pc bg-cyan mb-8 mb-lg-11">
            <div className="vertical-slider projects-carousel">
            <Slider  {...settings}>
            {data.folio.edges.map((post, key) => (
              <div className="slide" key={key}>
                <div className="row w-md-100">
                  <div className="col-md-6 d-flex">
                    <div className="info-block d-flex align-items-center w-100 p-4 p-md-6">
                      <div className="block-left"><span className="badge badge-white"><span className="icon-heart text-pink"></span> featured project</span></div>
                        <div className="block-center pb-8"><div className="img-holder w-100"><img src={`${post.node.acf.image.url.localFile.publicURL}`} alt="" width="315" height="290" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="info-block d-flex align-items-stretch w-100 p-4 p-md-6">
                      <div className="block-left">
                        <strong className="title text-grey">{post.node.acf.client_name}</strong>
                      </div>
                      <div>
                        <h3 className="text-blue"><Link to={`/folio/${post.node.slug}`}>{post.node.acf.tagline}</Link></h3>
                        <p className="card-text">{post.node.acf.intro}</p>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              ))}
               </Slider>
               </div>
           
          </div>
         <div className="row align-items-center justify-content-between mb-15">
               <div className="col-md-12 text-center">
               <a href="/folio" className="btn btn-primary">See more of our work</a>
            </div>
         </div>
          <section className="info-section mb-8 mb-lg-25 services-section">
          <Fade triggerOnce="true" direction="top">
            <div className="intro mb-8 mb-lg-15 ">
              <h2>Service & approach</h2>
              <p>Over the past 18 years we have perfected our project process so that we can produce exceptional results every time.<br />We achieve this by seeking to understand what makes your organisation, its users and industry as a whole tick.<br />By understanding intimately what your organisation and users are trying to achieve we can deliver digital solutions that get results.</p>
            </div>
            <div className="row"> 
              <Fade triggerOnce="true" direction="top" className="col-12 col-md-3 mb-4 text-center">
                <Link className="hover-elevation" to="/services#user_experience">
                  <div className="img-holder mb-5 pb-8 has-decor-shadow">
                  <img src={UXImage} width="97" height="97" alt="" /></div>
                  <h3 className="text-grey">User Experience</h3>
                  <p>Creating a successful user experience starts with understanding your users needs</p>
                </Link>  
              </Fade>
              <Fade triggerOnce="true" direction="top" className="col-12 col-md-3 mb-4 text-center">
                <a className="hover-elevation"  href="/services#user_interface">
               <div className="img-holder mb-5 pb-8 has-decor-shadow"><img src={UIImage} width="97" height="97" alt="" /></div>
                  <h3 className="text-grey">Design</h3>
                <p>Our experienced digital designers work with you to co-design applications that resonate with your customers and communicate your businesses messages</p>
            </a>
              </Fade>
              <Fade triggerOnce="true" direction="top" className="col-12 col-md-3 mb-4 text-center">
            <a className="hover-elevation"  href="/services#web_development">  
                   <div className="img-holder mb-5 pb-8 has-decor-shadow"><img src={WebImage} width="97" height="97" alt="" /></div>
                   <h3 className="text-grey">Development</h3>
             <p>Our team of developers use the latest methods and systems to ensure your project outcomes are fast to load, secure and compatible across browsers and devices</p>  
            </a>  
              </Fade>
              <Fade triggerOnce="true" direction="top" className="col-12 col-md-3 mb-4 text-center">
            <a className="hover-elevation"  href="/services#support">  
                  <div className="img-holder mb-5 pb-8 has-decor-shadow"><img src={BrandImage} width="97" height="97" alt="" /></div>
                  <h3 className="text-grey">Support & Maintenance</h3>
                  <p>We see our partnership continue post-launch of your project through support for maintenance  and ongoing improvements, based on business and user feedback to help you roadmap your digital presence and assets</p>
            </a>  
              </Fade>
            </div>
            </Fade>
          </section>
          <Fade triggerOnce="true" direction="top" className="content-box testimonial-section  text-center bg-light-black text-white">
            
            <Slider className="vertical-slider p-8 p-lg-10" {...settings}>
              <div className="slide" key="1">
                <div className="row justify-content-center">
                  <div className="col-md-7">
                    <span className="icon-chatbox font-xl text-pink d-block mb-3">&nbsp;</span>
                    <h2 className="text-white">Succesful partnerships</h2>
                    <blockquote className="mb-6">
                      <p>“Webplace created five great Wordpress websites within a tight timeframe and budget.</p><p>They were professional, easy to work with and made it a priority to ensure they understood what our requirements were in order to successfully launch the websites.</p><p>Working with the Webplace team was a great experience.”</p>
                      <cite>Lisa Sisay, Cyclone Tools</cite>
                    </blockquote>
                  </div>
                </div>
              </div>
              <div className="slide"  key="2">
                <div className="row justify-content-center">
                  <div className="col-md-7">
                    <span className="icon-chatbox font-xl text-pink d-block mb-3">&nbsp;</span>
                    <h2 className="text-white">Succesful partnerships</h2>
                    <blockquote className="mb-6">
                      <p>“Not only were the Webplace team a joy to work with, but the results of the project have been met with great acclaim from our staff, our users and the public.</p><p>We cannot recommend Webplace highly enough and are thrilled to have partnered with them.”</p>
                      <cite>Robyn Diamond, Australian Academy of Science</cite>
                    </blockquote>
                  </div>
                </div>
              </div>
              <div className="slide"  key="3">
                <div className="row justify-content-center">
                  <div className="col-md-7">
                    <span className="icon-chatbox font-xl text-pink d-block mb-3">&nbsp;</span>
                    <h2 className="text-white">Succesful partnerships</h2>
                    <blockquote className="mb-6">
                      <p>“Webplace were more than a vendor in our website project, they were a true partner that guided us through the process from start to finish.</p><p>The team were a joy to work with and navigated difficult conversations with patience and professionalism.”</p>
                      <cite>Liz Greenbank, InfoXchange</cite>
                    </blockquote>
                  </div>
                </div>
              </div>
              </Slider>
            
            <div className="img-decor bottom-right has-opacity FadeUp"><img src={'/img3.svg'} width="170" height="170" alt="" /></div>
          </Fade>
        </div>
      </div>
      <Fade triggerOnce="true" direction="top" className="container pb-8 pb-lg-18">
        <div className="row align-items-center justify-content-between ">
          <div className="col-md-5 FadeUp">
            <h2>Who we help</h2>
            <p>We have worked across many industries including but not limited to Government, Non-profit, Utilities, Education, Retail, B2B & B2C.</p>
            <p>With an intimate understanding of your industry and business-specific needs, we work together to deliver a co-designed solution that solves real problems</p>  
         
          </div>
          <div className="col-md-6 FadeUp">
          <Accordion className="list-unstyled accordion">
            
              <AccordionItem className="anchor-box border box-shadow bg-white rounded-10 overflow-hidden mb-6">
              <AccordionItemHeading>
                <AccordionItemButton className="opener text-blue font-weight-medium d-flex align-items-center py-4 pl-4 pr-8">
                  <span className="ico-holder text-center"><img src={'/ico-gov.svg'} width="56" height="50" alt="" /></span> <span className="text pl-4 pl-lg-10">Government</span>
                </AccordionItemButton>
              </AccordionItemHeading> 
                <AccordionItemPanel className="slide">
                  <div className="wrap px-4 pb-4">
                    <p>18 years experience working with many State and Federal Government departments and Emergency agencies across Australia</p>
                    <a href="/government/" className="more-link text-uppercase">See how we work with Government</a>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem className="anchor-box border box-shadow bg-white rounded-10 overflow-hidden mb-6">
              <AccordionItemHeading>
                <AccordionItemButton className="opener text-blue font-weight-medium d-flex align-items-center py-4 pl-4 pr-8">
                  <span className="ico-holder text-center"><img src={'/ico-profit.svg'} width="37" height="50" alt="" /></span> <span className="text pl-4 pl-lg-10">Non-profit</span>
                </AccordionItemButton>
              </AccordionItemHeading> 
                <AccordionItemPanel className="slide">
                <div className="wrap px-4 pb-4">
                  <p>Using our knowledge for good to work with NFPs to assist some of the most vulnerable in our community</p>
                  <a href="/not-for-profit/" className="more-link text-uppercase">See how we work with non-profit organisations</a>
                </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem className="anchor-box border box-shadow bg-white rounded-10 overflow-hidden mb-6">
              <AccordionItemHeading>
                <AccordionItemButton className="opener text-blue font-weight-medium d-flex align-items-center py-4 pl-4 pr-8">
                <span className="ico-holder text-center"><img src={'/ico-utilities.svg'} width="56" height="50" alt="" /></span> <span className="text pl-4 pl-lg-10">Utilities</span>
                </AccordionItemButton>
              </AccordionItemHeading> 
                <AccordionItemPanel className="slide">
                <div className="wrap px-4 pb-4">
                  <p>Working with large utilities to achieve improved customer experience and digital transitions assets</p>
                  <a href="/utilities" className="more-link text-uppercase">See how we work with Utilities</a>
                </div>
              </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem className="anchor-box border box-shadow bg-white rounded-10 overflow-hidden mb-6">
              <AccordionItemHeading>
                <AccordionItemButton className="opener text-blue font-weight-medium d-flex align-items-center py-4 pl-4 pr-8">
                <span className="ico-holder text-center"><img src={'/ico-hat.svg'} width="56" height="50" alt="" /></span> <span className="text pl-4 pl-lg-10">Education</span>
                </AccordionItemButton>
              </AccordionItemHeading> 
                <AccordionItemPanel className="slide">
                <div className="wrap px-4 pb-4">
                  <p>Over 10 years working with many of the University institutions around Australia to improve digital experience for students, improved technical eco-systems and digital transformation outcomes</p>
                  <a href="/education" className="more-link text-uppercase">See how we work with Education</a>
                  </div>
              </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
    </Fade>
    <Fade className="bg-light-cyan logo-section pt-12 pb-6 pt-lg-20 pb-lg-11">
        <div className="container">
            <div className="heading-block  mb-10 mb-lg-21 ">
              <h2 className="h1 fw-700 mb-3 mb-md-5">Clients we work with</h2>
              <p className="mb-0">A small selection of clients across various industries we have been fortunate enough to partner with.</p>
            </div>
            
            <ul className="logo-list alt-style text-center d-flex flex-wrap align-items-center justify-content-center mx-n3  d-print-none">
              <li className="px-3 mb-6 mb-lg-8">
                    <img src={Telstra} alt="telstra" width="130" height="130" />                  
              </li>
              <li className="px-3 mb-6 mb-lg-8">
                    <img src={AAOS}  alt="Australian Academy of Science" width="120" height="120" />
              </li>
              <li className="px-3 mb-6 mb-lg-8">
                    <img src={VicGov}  alt="Victoria state government" width="100" height="100"  />
              </li>
              <li className="px-3 mb-6 mb-lg-8">
                    <img src={Nylex}  alt="nylex" width="100" height="100" />
              </li>
              <li className="px-3 mb-6 mb-lg-8">
                    <img src={Swinburne}  alt="swin burne" width="130" height="130" />
              </li>
              <li className="px-3 mb-6 mb-lg-8">
                    <img src={Moose}  alt="moose" width="118" height="118" />
              </li>
              <li className="px-3 mb-6 mb-lg-8">
                    <img src={Transurban}  alt="transurban" width="148" height="148"/>
              </li>
              <li className="px-3 mb-6 mb-lg-8">
                    <img src={InfoXchange}  alt="infoxchange" width="148" height="148" />
              </li>
              <li className="px-3 mb-6 mb-lg-8">                  
                    <img src={SRO}  alt="state revenue office victoria" width="128" height="128" />
              </li>
              <li className="px-3 mb-6 mb-lg-8">                  
                    <img src={GWM} alt="GWM Water" width="128" height="128" />
              </li>
              <li className="px-3 mb-6 mb-lg-8">                  
                    <img src={UOM} alt="University of Melbourne" width="128" height="128" />
              </li>
              <li className="px-3 mb-6 mb-lg-8">                  
                    <img src={Telkom} alt="Telkom Telstra" width="128" height="128" />
              </li>
              <li className="px-3 mb-6 mb-lg-8">                  
                    <img src={Coliban} alt="Coliban Water" width="128" height="128"/>
              </li>
              <li className="px-3 mb-6 mb-lg-8">                  
                    <img src={VicPol} alt="Victoria Police" width="128" height="128"/>
              </li>
              <li className="px-3 mb-6 mb-lg-8">                  
                    <img src={VTAC} alt="VTAC" width="128" height="128"/>
              </li>
              <li className="px-3 mb-6 mb-lg-8">                  
                    <img src={WorkSafe} alt="WorkSafe" width="128" height="128"/>
              </li>
              <li className="px-3 mb-6 mb-lg-8">                  
                    <img src={EA} alt="Energy Australia" width="108" height="108"/>
              </li>
              <li className="px-3 mb-6 mb-lg-8">                  
                    <img src={CFA} alt="Country Fire Authority" width="128" height="128"/>
              </li>
            </ul>
        </div>
      </Fade>          
      <Fade triggerOnce="true" direction="top" className="bg-decor-section has-bottom decor-fade-grey">
        <div className="container py-8 py-lg-18">
          <div className="-b">
            <div className="intro mb-8">
              <h2 className="mb-4">Latest News & Insights</h2>
            </div>
          </div>
          <div className="row mb-8 mb-lg-14 -b">
          {data.blog.edges.map((post, key) => (
            <div className="col-md-4 d-flex mb-6">
            <Link to={`/blog/${post.node.slug}`} className="card info-card hover-elevation rounded-10 overflow-hidden">
                <div className="card-body">
                  <div className="ico-holder mb-4"><img src={`${post.node.acf.thumbnail_image.localFile.childImageSharp.fluid.srcWebp}`} alt="" width="315" height="193" className="rounded" /></div>
                  <span class="date">{post.node.date}</span>
                  <h3 className="h5 text-blue">{post.node.acf.tagline}</h3>
                  <p className="card-text">{post.node.acf.intro_text}</p>
                </div>
              </Link>
            </div>
            ))}
          </div>
          <div className="row align-items-center justify-content-between mb-15">
               <div className="col-md-12 text-center">
               <a href="/blog" className="btn btn-primary">Read more News &amp; Insights</a>
            </div>
         </div>
        </div>
      </Fade>
      <Fade triggerOnce="true" direction="top" className="bg-decor-section has-bottom decor-fade-blue">
        <div className="container py-8 py-lg-18">
          <div className="-b">
            <div className="intro mb-8">
              <h2 className="mb-4">Technologies we work with</h2>
              <p>We have a strong connection with open source technologies. With no propietary licence fees, your budget can be spent on tailoring a solution fit for purpose. We are experts in the following world class Content Management Systems.</p>
            </div>
          </div>
          <div className="row mb-8 mb-lg-14 -b">
            <div className="col-md-3 d-flex mb-6">
              <a href="/jamstack-websites/" className="card info-card hover-elevation rounded-10 overflow-hidden">
                <div className="card-body">
                  <div className="ico-holder mb-4"><img src={Jamstack} width="150" height="25" alt="Jamstack websites" /></div>
                  <h3 className="h5 text-blue">Jamstack <span className="icon-arrow-right text-pink"></span></h3>
                  <p>Super fast websites and apps, secure, accessible and scalable</p>
                </div>
              </a>
            </div> 

            <div className="col-md-3 d-flex mb-6">
              <a href="/wordpress-developers-melbourne/" className="card info-card hover-elevation rounded-10 overflow-hidden">
                <div className="card-body">
                  <div className="ico-holder mb-4"><img src={WordPress} width="70" height="70" alt="wordpress" /></div>
                  <h3 className="h5 text-blue">Wordpress <span className="icon-arrow-right text-pink"></span></h3>
                  <p>Incredibly easy to use, more then just a blogging platform. Most used open source CMS in the world.</p>
                </div>
              </a>
            </div>
            <div className="col-md-3 d-flex mb-6">
              <a href="/drupal-developers-melbourne/" className="card info-card hover-elevation rounded-10 overflow-hidden">
                <div className="card-body">
                  <div className="ico-holder mb-4"><img src={Drupal} width="64" height="71" alt="drupal" /></div>
                  <h3 className="h5 text-blue">Drupal <span className="icon-arrow-right text-pink"></span></h3>
                  <p>Drupal is powerful, fast and secure. One of the preferred CMS for Australian Federal & State Governments.</p>
                </div>
              </a>
            </div>
            <div className="col-md-3 d-flex mb-6">
              <a href="/joomla-web-development/" className="card info-card hover-elevation rounded-10 overflow-hidden">
                <div className="card-body">
                  <div className="ico-holder mb-4"><img src={Joomla} width="61" height="61" alt="joomla" /></div>
                  <h3 className="h5 text-blue">Joomla <span className="icon-arrow-right text-pink"></span></h3>
                  <p>A great all round solution. Easy to use and can be used for many use cases.</p>
                </div>
              </a>
            </div>
          </div>
          <div className="content-box bg-blue text-center text-white p-8 p-lg-10"><div className="row justify-content-center"><div className="col-md-6"> <span className="icon-bulb bobble-anim font-xl text-pink d-block mb-10"></span><h2 className="text-white">Let’s work together</h2><p>Looking for a long term digital partner to help you realise your digital goals?</p> <a href="/lets-talk" className="btn btn-primary">Request a free quote</a></div></div><div className="img-decor left-big"><img src={LetsWorkTogetherImage} alt="" width="547" height="343" /></div></div>
        </div>
      </Fade>
    </main>


  </Layout>
)}

export const pageQuery = graphql`
   query HomePageQuery  {
      folio:allWordpressPost(filter: {categories: {elemMatch: {slug: {eq: "folio"}}},acf: {featured_: {eq: true}}}, sort: {fields: acf___featured_ordering, order: ASC}, limit: 4) {
         edges {
            node {
                  id
                  slug
                  path
                  status
                  template
                  format
                  title
                  content
                  acf {
                   intro
                   tagline
                   client_name
                   project_name
                   featured_
                   featured_ordering
                   intro_text
                   image {
                     url {
                      localFile {
                        publicURL
                      }
                     }
                   }
                 }
                 
              }
            
         }
      }
      
      blog:allWordpressPost(filter: {categories: {elemMatch: {slug: {eq: "blog"}}}}, sort: {fields: date, order: DESC}, limit: 3) {
        edges {
           node {
                 id
                 slug
                 path
                 status
                 template
                 format
                 title
                 content
                 date(formatString: "DD, MMM, YYYY")
                 acf {
                  intro
                  tagline
                  client_name
                  project_name
                  featured_
                  featured_ordering
                  intro_text
                  thumbnail_image {
                    localFile {
                      publicURL
                      childImageSharp {
                        fluid {
                          srcWebp
                          srcSetWebp
                          originalImg
                          originalName
                        }
                      }
                    }
                  }
                }
                
             }
           
        }
     }
      
   }
`
export default homeTemplate;